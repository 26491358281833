// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
{ code: 'AF', en: 'Afghanistan', fr: 'Afghanistan', es: 'Afganistán', de: 'Afghanistan', it: 'Afghanistan' },
{ code: 'AL', en: 'Albania', fr: 'Albanie', es: 'Albania', de: 'Albanien', it: 'Albania' },
{ code: 'DZ', en: 'Algeria', fr: 'Algérie', es: 'Argel', de: 'Algerien', it: 'Algeria' },
{ code: 'AD', en: 'Andorra', fr: 'Andorre', es: 'Andorra', de: 'Andorra', it: 'Andorra' },
{ code: 'AO', en: 'Angola', fr: 'Angola', es: 'Angola', de: 'Angola', it: 'Angola' },
{ code: 'AI', en: 'Anguilla', fr: 'Anguilla', es: 'Anguila', de: 'Anguilla', it: 'Anguilla' },
{ code: 'AQ', en: 'Antarctica', fr: 'Antarctique', es: 'Antártida', de: 'Antarktika', it: 'Antartide' },
{ code: 'AG', en: 'Antigua and Barbuda', fr: 'Antigua-et-Barbuda', es: 'Antigua y Barbuda', de: 'Antigua und Barbuda', it: 'Antigua e Barbuda' },
{ code: 'SA', en: 'Saudi Arabia', fr: 'Arabie Saoudite', es: 'Arabia Saudita', de: 'Saudi-Arabien' , it: 'Arabia Saudita' },
{ code: 'AR', en: 'Argentina', fr: 'Argentine', es: 'Argentina', de: 'Argentinien', it: 'Argentina' },
{ code: 'AM', en: 'Armenia', fr: 'Arménie', es: 'Armenia', de: 'Armenien', it: 'Armenia' },
{ code: 'AW', en: 'Aruba', fr: 'Aruba', es: 'Aruba', de: 'Aruba', it: 'Aruba' },
{ code: 'AU', en: 'Australia', fr: 'Australie', es: 'Australia', de: 'Australien', it: 'Australia' },
{ code: 'AT', en: 'Austria', fr: 'Autriche', es: 'Austria', de: 'Österreich', it: 'Austria' },
{ code: 'AZ', en: 'Azerbaijan', fr: 'Azerbaïdjan', es: 'Azerbaiyán', de: 'Aserbaidschan', it: 'Azerbaijan' },
{ code: 'BS', en: 'Bahamas', fr: 'Bahamas', es: 'Bahamas', de: 'Bahamas', it: 'Bahamas' },
{ code: 'BH', en: 'Bahrain', fr: 'Bahreïn', es: 'Bahréin', de: 'Bahrain', it: 'Bahrain' },
{ code: 'BD', en: 'Bangladesh', fr: 'Bangladesh', es: 'Bangladesh', de: 'Bangladesch', it: 'Bangladesh' },
{ code: 'BB', en: 'Barbados', fr: 'Barbade', es: 'Barbados', de: 'Barbados', it: 'Barbados' },
{ code: 'BE', en: 'Belgium', fr: 'Belgique', es: 'Bélgica', de: 'Belgien', it: 'Belgio' },
{ code: 'BZ', en: 'Belize', fr: 'Belize', es: 'Belice', de: 'Belize', it: 'Belize' },
{ code: 'BJ', en: 'Benin', fr: 'Bénin', es: 'Benin', de: 'Benin', it: 'Benin' },
{ code: 'BM', en: 'Bermuda', fr: 'Bermudes', es: 'Bermudas', de: 'Bermuda', it: 'Bermuda' },
{ code: 'BT', en: 'Bhutan', fr: 'Bhoutan', es: 'Bhután', de: 'Bhutan', it: 'Bhutan' },
{ code: 'BY', en: 'Belarus', fr: 'Biélorussie', es: 'Belarús', de: 'Belarus', it: 'Bielorussia' },
{ code: 'BO', en: 'Bolivia', fr: 'Bolivie', es: 'Bolivia', de: 'Bolivien', it: 'Bolivia' },
{ code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fr: 'Pays-Bas caribéens', es: 'Caribe Neerlandés', de: 'Bonaire, Sint Eustatius und Saba', it: 'Bonaire, Sint Eustatius e Saba' },
{ code: 'BA', en: 'Bosnia and Herzegovina', fr: 'Bosnie-Herzégovine', es: 'Bosnia y Herzegovina', de: 'Bosnien und Herzegowina', it: 'Bosnia ed Erzegovina' },
{ code: 'BW', en: 'Botswana', fr: 'Botswana', es: 'Botsuana', de: 'Botswana', it: 'Botswana' },
{ code: 'BR', en: 'Brazil', fr: 'Brésil', es: 'Brasil', de: 'Brasilien', it: 'Brasile' },
{ code: 'BN', en: 'Brunei Darussalam', fr: 'Brunei Darussalam', es: 'Brunéi', de: 'Brunei Darussalam', it: 'Brunei' },
{ code: 'BG', en: 'Bulgaria', fr: 'Bulgarie', es: 'Bulgaria', de: 'Bulgarien', it: 'Bulgaria' },
{ code: 'BF', en: 'Burkina Faso', fr: 'Burkina Faso', es: 'Burkina Faso', de: 'Burkina Faso', it: 'Burkina Faso' },
{ code: 'BI', en: 'Burundi', fr: 'Burundi', es: 'Burundi', de: 'Burundi', it: 'Burundi' },
{ code: 'KH', en: 'Cambodia', fr: 'Cambodge', es: 'Camboya', de: 'Kambodscha', it: 'Cambogia' },
{ code: 'CM', en: 'Cameroon', fr: 'Cameroun', es: 'Camerún', de: 'Kamerun', it: 'Camerun' },
{ code: 'CA', en: 'Canada', fr: 'Canada', es: 'Canadá', de: 'Kanada' , it: 'Canada' },
{ code: 'CV', en: 'Cape Verde', fr: 'Cap-Vert', es: 'Cabo Verde', de: 'Kap Verde' , it: 'Capo Verde' },
{ code: 'TD', en: 'Chad', fr: 'Tchad', es: 'Chad', de: 'Tschad' , it: 'Chad' },
{ code: 'CL', en: 'Chile', fr: 'Chili', es: 'Chile', de: 'Chile' , it: 'Cile' },
{ code: 'CN', en: 'China', fr: 'Chine', es: 'China', de: 'China, Volksrepublik' , it: 'Cina' },
{ code: 'CY', en: 'Cyprus', fr: 'Chypre', es: 'Chipre', de: 'Zypern' , it: 'Cipro' },
{ code: 'VA', en: 'Holy See (Vatican City State)', fr: 'Saint-Siège (Vatican)', es: 'Ciudad del Vaticano', de: 'Vatikanstadt' , it: 'Città del Vaticano' },
{ code: 'CO', en: 'Colombia', fr: 'Colombie', es: 'Colombia', de: 'Kolumbien' , it: 'Colombia' },
{ code: 'KM', en: 'Comoros', fr: 'Comores', es: 'Comoros', de: 'Komoren' , it: 'Comore' },
{ code: 'KP', en: 'Korea, Democratic People\'s Republic of', fr: 'Corée du Nord', es: 'Corea del Norte', de: 'Korea, Demokratische Volksrepublik (Nordkorea)' , it: 'Corea del Nord' },
{ code: 'KR', en: 'Korea, Republic of', fr: 'Corée du Sud', es: 'Corea del Sur', de: 'Korea, Republik (Südkorea)' , it: 'Corea del Sud' },
{ code: 'CI', en: 'Côte d\'Ivoire', fr: 'Côte d’Ivoire', es: 'Costa de Marfil', de: 'Côte d’Ivoire' , it: 'Costa d\'Avorio' },
{ code: 'CR', en: 'Costa Rica', fr: 'Costa Rica', es: 'Costa Rica', de: 'Costa Rica' , it: 'Costa Rica' },
{ code: 'HR', en: 'Croatia', fr: 'Croatie', es: 'Croacia', de: 'Kroatien' , it: 'Croazia' },
{ code: 'CU', en: 'Cuba', fr: 'Cuba', es: 'Cuba', de: 'Kuba' , it: 'Cuba' },
{ code: 'CW', en: 'Curaçao', fr: 'Curaçao', es: 'Curazao', de: 'Curaçao' , it: 'Curaçao' },
{ code: 'DK', en: 'Denmark', fr: 'Danemark', es: 'Dinamarca', de: 'Dänemark' , it: 'Danimarca' },
{ code: 'DM', en: 'Dominica', fr: 'Dominique', es: 'Domínica', de: 'Dominica' , it: 'Dominica' },
{ code: 'EC', en: 'Ecuador', fr: 'Équateur', es: 'Ecuador', de: 'Ecuador' , it: 'Ecuador' },
{ code: 'EG', en: 'Egypt', fr: 'Égypte', es: 'Egipto', de: 'Ägypten' , it: 'Egitto' },
{ code: 'SV', en: 'El Salvador', fr: 'Salvador', es: 'El Salvador', de: 'El Salvador' , it: 'El Salvador' },
{ code: 'AE', en: 'United Arab Emirates', fr: 'Émirats Arabes Unis', es: 'Emiratos Árabes Unidos', de: 'Vereinigte Arabische Emirate' , it: ' Emirati Arabi Uniti' },
{ code: 'ER', en: 'Eritrea', fr: 'Érythrée', es: 'Eritrea', de: 'Eritrea' , it: 'Eritrea' },
{ code: 'EE', en: 'Estonia', fr: 'Estonie', es: 'Estonia', de: 'Estland' , it: 'Estonia' },
{ code: 'SZ', en: 'Swaziland', fr: 'Eswatini', es: 'Suazilandia', de: 'Swasiland' , it: ' eSwatini' },
{ code: 'ET', en: 'Ethiopia', fr: 'Éthiopie', es: 'Etiopía', de: 'Äthiopien' , it: 'Etiopia' },
{ code: 'FO', en: 'Faroe Islands', fr: 'Îles Féroé', es: 'Islas Faroe', de: 'Färöer' , it: 'Fær Øer' },
{ code: 'FJ', en: 'Fiji', fr: 'Fidji', es: 'Fiji', de: 'Fidschi' , it: 'Figi' },
{ code: 'PH', en: 'Philippines', fr: 'Philippines', es: 'Filipinas', de: 'Philippinen' , it: 'Filippine' },
{ code: 'FI', en: 'Finland', fr: 'Finlande', es: 'Finlandia', de: 'Finnland' , it: 'Finlandia' },
{ code: 'FR', en: 'France', fr: 'France', es: 'Francia', de: 'Frankreich' , it: 'Francia' },
{ code: 'GA', en: 'Gabon', fr: 'Gabon', es: 'Gabón', de: 'Gabun' , it: 'Gabon' },
{ code: 'GM', en: 'Gambia', fr: 'Gambie', es: 'Gambia', de: 'Gambia' , it: 'Gambia' },
{ code: 'GE', en: 'Georgia', fr: 'Géorgie', es: 'Georgia', de: 'Georgien' , it: 'Georgia' },
{ code: 'GS', en: 'South Georgia and the South Sandwich Islands', fr: 'Géorgie du Sud et les îles Sandwich du Sud', es: 'Georgia del Sur e Islas Sandwich deSur', de: 'Südgeorgien und die Südlichen Sandwichinseln' , it: 'Georgia del Sud e Isole Sandwich Australi' },
{ code: 'DE', en: 'Germany', fr: 'Allemagne', es: 'Alemania', de: 'Deutschland' , it: 'Germania' },
{ code: 'GH', en: 'Ghana', fr: 'Ghana', es: 'Ghana', de: 'Ghana' , it: 'Ghana' },
{ code: 'JM', en: 'Jamaica', fr: 'Jamaïque', es: 'Jamaica', de: 'Jamaika' , it: 'Giamaica' },
{ code: 'JP', en: 'Japan', fr: 'Japon', es: 'Japón', de: 'Japan' , it: 'Giappone' },
{ code: 'GI', en: 'Gibraltar', fr: 'Gibraltar', es: 'Gibraltar', de: 'Gibraltar' , it: 'Gibilterra' },
{ code: 'DJ', en: 'Djibouti', fr: 'Djibouti', es: 'Yibuti', de: 'Dschibuti' , it: 'Gibuti' },
{ code: 'JO', en: 'Jordan', fr: 'Jordanie', es: 'Jordania', de: 'Jordanien' , it: 'Giordania' },
{ code: 'GR', en: 'Greece', fr: 'Grèce', es: 'Grecia', de: 'Griechenland' , it: 'Grecia' },
{ code: 'GD', en: 'Grenada', fr: 'Grenade', es: 'Granada', de: 'Grenada' , it: 'Grenada' },
{ code: 'GL', en: 'Greenland', fr: 'Groenland', es: 'Groenlandia', de: 'Grönland' , it: 'Groenlandia' },
{ code: 'GP', en: 'Guadeloupe', fr: 'Guadeloupe', es: 'Guadalupe', de: 'Guadeloupe' , it: 'Guadalupa' },
{ code: 'GU', en: 'Guam', fr: 'Guam', es: 'Guam', de: 'Guam' , it: 'Guam' },
{ code: 'GT', en: 'Guatemala', fr: 'Guatemala', es: 'Guatemala', de: 'Guatemala' , it: 'Guatemala' },
{ code: 'GG', en: 'Guernsey', fr: 'Guernesey', es: 'Guernsey', de: 'Guernsey' , it: 'Guernsey' },
{ code: 'GN', en: 'Guinea', fr: 'Guinée', es: 'Guinea', de: 'Guinea' , it: 'Guinea' },
{ code: 'GQ', en: 'Equatorial Guinea', fr: 'Guinée équatoriale', es: 'Guinea Ecuatorial', de: 'Äquatorialguinea' , it: 'Guinea Equatoriale' },
{ code: 'GW', en: 'Guinea-Bissau', fr: 'Guinée-Bissau', es: 'Guinea-Bissau', de: 'Guinea-Bissau' , it: 'Guinea-Bissau' },
{ code: 'GY', en: 'Guyana', fr: 'Guyane', es: 'Guayana', de: 'Guyana' , it: 'Guyana' },
{ code: 'GF', en: 'French Guiana', fr: 'Guyane française', es: 'Guayana Francesa', de: 'Französisch-Guayana' , it: 'Guyana francese' },
{ code: 'HT', en: 'Haiti', fr: 'Haïti', es: 'Haití', de: 'Haiti' , it: 'Haiti' },
{ code: 'HN', en: 'Honduras', fr: 'Honduras', es: 'Honduras', de: 'Honduras' , it: 'Honduras' },
{ code: 'HK', en: 'Hong Kong', fr: 'Hong Kong', es: 'Hong Kong', de: 'Hongkong' , it: 'Hong Kong' },
{ code: 'IN', en: 'India', fr: 'Inde', es: 'India', de: 'Indien' , it: 'India' },
{ code: 'ID', en: 'Indonesia', fr: 'Indonésie', es: 'Indonesia', de: 'Indonesien' , it: 'Indonesia' },
{ code: 'IR', en: 'Iran, Islamic Republic of', fr: 'Iran', es: 'Irán', de: 'Iran, Islamische Republik' , it: 'Iran' },
{ code: 'IQ', en: 'Iraq', fr: 'Irak', es: 'Irak', de: 'Irak' , it: 'Iraq' },
{ code: 'IE', en: 'Ireland', fr: 'Irlande', es: 'Irlanda', de: 'Irland' , it: 'Irlanda' },
{ code: 'IS', en: 'Iceland', fr: 'Islande', es: 'Islandia', de: 'Island' , it: 'Islanda' },
{ code: 'BV', en: 'Bouvet Island', fr: 'Île Bouvet', es: 'Isla Bouvet', de: 'Bouvetinsel', it: 'Isola Bouvet' },
{ code: 'JE', en: 'Jersey', fr: 'Jersey', es: 'Jersey', de: 'Jersey (Kanalinsel)' , it: 'Isola di Jersey' },
{ code: 'IM', en: 'Isle of Man', fr: 'Ile de Man', es: 'Isla de Man', de: 'Insel Man' , it: 'Isola di Man' },
{ code: 'CX', en: 'Christmas Island', fr: 'Île Christmas', es: 'Islas Christmas', de: 'Weihnachtsinsel' , it: 'Isola di Natale' },
{ code: 'AX', en: 'Åland Islands', fr: 'Îles Åland', es: 'Islas Áland', de: 'Åland', it: 'Isole Åland' },
{ code: 'KY', en: 'Cayman Islands', fr: 'Iles Cayman', es: 'Islas Caimán', de: 'Kaimaninseln' , it: 'Isole Cayman' },
{ code: 'CC', en: 'Cocos (Keeling) Islands', fr: 'Îles Cocos', es: 'Islas Cocos', de: 'Kokosinseln' , it: 'Isole Cocos' },
{ code: 'CK', en: 'Cook Islands', fr: 'Îles Cook', es: 'Islas Cook', de: 'Cookinseln' , it: 'Isole Cook' },
{ code: 'FK', en: 'Falkland Islands (Malvinas)', fr: 'Îles Falkland', es: 'Islas Malvinas', de: 'Falklandinseln' , it: 'Isole Falkland' },
{ code: 'HM', en: 'Heard Island and McDonald Islands', fr: 'Îles Heard-et-MacDonald', es: 'Islas Heard y McDonald', de: 'Heard und McDonaldinseln' , it: 'Isole Heard e McDonald' },
{ code: 'MP', en: 'Northern Mariana Islands', fr: 'Îles Mariannes du Nord', es: 'Islas Marianas del Norte', de: 'Nördliche Marianen' , it: 'Isole Marianne Settentrionali' },
{ code: 'MH', en: 'Marshall Islands', fr: 'Îles Marshall', es: 'Islas Marshall', de: 'Marshallinseln' , it: 'Isole Marshall' },
{ code: 'UM', en: 'United States Minor Outlying Islands', fr: 'Îles mineures éloignées des États-Unis', es: 'Islas Ultramarinas Menores de Estados Unidos', de: 'United States Minor Outlying Islands' , it: '  Isole minori esterne degli Stati Uniti d\'America' },
{ code: 'PN', en: 'Pitcairn', fr: 'Pitcairn', es: 'Islas Pitcairn', de: 'Pitcairninseln' , it: 'Isole Pitcairn' },
{ code: 'SB', en: 'Solomon Islands', fr: 'Îles Salomon', es: 'Islas Solomón', de: 'Salomonen' , it: 'Isole Salomone' },
{ code: 'TC', en: 'Turks and Caicos Islands', fr: 'Îles Turques-et-Caïques', es: 'Islas Turcas y Caicos', de: 'Turks- und Caicosinseln' , it: 'Isole Turks e Caicos' },
{ code: 'VI', en: 'Virgin Islands, U.S.', fr: 'Îles Vierges américaines', es: 'Islas Vírgenes de los Estados Unidos de América', de: 'Amerikanische Jungferninseln' , it: 'Isole Vergini Americane' },
{ code: 'VG', en: 'Virgin Islands, British', fr: 'British Virgin Islands', es: 'Islas Vírgenes Británicas', de: 'Britische Jungferninseln' , it: 'Isole Vergini Britanniche' },
{ code: 'IL', en: 'Israel', fr: 'Israël', es: 'Israel', de: 'Israel' , it: 'Israele' },
{ code: 'IT', en: 'Italy', fr: 'Italie', es: 'Italia', de: 'Italien' , it: 'Italia' },
{ code: 'KZ', en: 'Kazakhstan', fr: 'Kazakhstan', es: 'Kazajstán', de: 'Kasachstan' , it: 'Kazakistan' },
{ code: 'KE', en: 'Kenya', fr: 'Kenya', es: 'Kenia', de: 'Kenia' , it: 'Kenya' },
{ code: 'KG', en: 'Kyrgyzstan', fr: 'Kirghizistan', es: 'Kirguistán', de: 'Kirgisistan' , it: 'Kirghizistan' },
{ code: 'KI', en: 'Kiribati', fr: 'Kiribati', es: 'Kiribati', de: 'Kiribati' , it: 'Kiribati' },
{ code: 'KW', en: 'Kuwait', fr: 'Koweït', es: 'Kuwait', de: 'Kuwait' , it: 'Kuwait' },
{ code: 'RE', en: 'Réunion', fr: 'Réunion', es: 'Reunión', de: 'Réunion' , it: 'La Riunione' },
{ code: 'LA', en: 'Laos', fr: 'Laos', es: 'Laos', de: 'Laos' , it: 'Laos' },
{ code: 'LS', en: 'Lesotho', fr: 'Lesotho', es: 'Lesotho', de: 'Lesotho' , it: 'Lesotho' },
{ code: 'LV', en: 'Latvia', fr: 'Lettonie', es: 'Letonia', de: 'Lettland' , it: 'Lettonia' },
{ code: 'LB', en: 'Lebanon', fr: 'Liban', es: 'Líbano', de: 'Libanon' , it: 'Libano' },
{ code: 'LR', en: 'Liberia', fr: 'Libéria', es: 'Liberia', de: 'Liberia' , it: 'Liberia' },
{ code: 'LY', en: 'Libya', fr: 'Libye', es: 'Libia', de: 'Libyen' , it: 'Libia' },
{ code: 'LI', en: 'Liechtenstein', fr: 'Liechtenstein', es: 'Liechtenstein', de: 'Liechtenstein' , it: 'Liechtenstein' },
{ code: 'LT', en: 'Lithuania', fr: 'Lituanie', es: 'Lituania', de: 'Litauen' , it: 'Lituania' },
{ code: 'LU', en: 'Luxembourg', fr: 'Luxembourg', es: 'Luxemburgo', de: 'Luxemburg' , it: 'Lussemburgo' },
{ code: 'MO', en: 'Macao', fr: 'Macao', es: 'Macao', de: 'Macau' , it: 'Macao' },
{ code: 'MK', en: 'North Macedonia', fr: 'Macédoine du Nord', es: 'Macedonia del Norte', de: 'Nordmazedonien' , it: 'Macedonia del Nord' },
{ code: 'MG', en: 'Madagascar', fr: 'Madagascar', es: 'Madagascar', de: 'Madagaskar' , it: 'Madagascar' },
{ code: 'MW', en: 'Malawi', fr: 'Malawi', es: 'Malawi', de: 'Malawi' , it: 'Malawi' },
{ code: 'MY', en: 'Malaysia', fr: 'Malaisie', es: 'Malasia', de: 'Malaysia' , it: 'Malaysia' },
{ code: 'MV', en: 'Maldives', fr: 'Maldives', es: 'Maldivas', de: 'Malediven' , it: 'Maldive' },
{ code: 'ML', en: 'Mali', fr: 'Mali', es: 'Mali', de: 'Mali' , it: 'Mali' },
{ code: 'MT', en: 'Malta', fr: 'Malte', es: 'Malta', de: 'Malta' , it: 'Malta' },
{ code: 'MA', en: 'Morocco', fr: 'Maroc', es: 'Marruecos', de: 'Marokko' , it: 'Marocco' },
{ code: 'MQ', en: 'Martinique', fr: 'Martinique', es: 'Martinica', de: 'Martinique' , it: 'Martinica' },
{ code: 'MR', en: 'Mauritania', fr: 'Mauritanie', es: 'Mauritania', de: 'Mauretanien' , it: 'Mauretania' },
{ code: 'MU', en: 'Mauritius', fr: 'Maurice', es: 'Mauricio', de: 'Mauritius' , it: 'Mauritius' },
{ code: 'YT', en: 'Mayotte', fr: 'Mayotte', es: 'Mayotte', de: 'Mayotte' , it: 'Mayotte' },
{ code: 'MX', en: 'Mexico', fr: 'Mexique', es: 'México', de: 'Mexiko' , it: 'Messico' },
{ code: 'FM', en: 'Micronesia, Federated States of', fr: 'Micronésie', es: 'Micronesia', de: 'Mikronesien' , it: 'Micronesia' },
{ code: 'MD', en: 'Moldova', fr: 'Moldavie', es: 'Moldova', de: 'Moldawien' , it: 'Moldavia' },
{ code: 'MC', en: 'Monaco', fr: 'Monaco', es: 'Mónaco', de: 'Monaco' , it: 'Monaco' },
{ code: 'MN', en: 'Mongolia', fr: 'Mongolie', es: 'Mongolia', de: 'Mongolei' , it: 'Mongolia' },
{ code: 'ME', en: 'Montenegro', fr: 'Monténégro', es: 'Montenegro', de: 'Montenegro' , it: 'Montenegro' },
{ code: 'MS', en: 'Montserrat', fr: 'Montserrat', es: 'Montserrat', de: 'Montserrat' , it: 'Montserrat' },
{ code: 'MZ', en: 'Mozambique', fr: 'Mozambique', es: 'Mozambique', de: 'Mosambik' , it: 'Mozambico' },
{ code: 'MM', en: 'Myanmar', fr: 'Myanmar', es: 'Myanmar', de: 'Myanmar' , it: 'Myanmar' },
{ code: 'NA', en: 'Namibia', fr: 'Namibie', es: 'Namibia', de: 'Namibia' , it: 'Namibia' },
{ code: 'NR', en: 'Nauru', fr: 'Nauru', es: 'Nauru', de: 'Nauru' , it: 'Nauru' },
{ code: 'NP', en: 'Nepal', fr: 'Népal', es: 'Nepal', de: 'Nepal' , it: 'Nepal' },
{ code: 'NI', en: 'Nicaragua', fr: 'Nicaragua', es: 'Nicaragua', de: 'Nicaragua' , it: 'Nicaragua' },
{ code: 'NE', en: 'Niger', fr: 'Niger', es: 'Níger', de: 'Niger' , it: 'Niger' },
{ code: 'NG', en: 'Nigeria', fr: 'Nigeria', es: 'Nigeria', de: 'Nigeria' , it: 'Nigeria' },
{ code: 'NU', en: 'Niue', fr: 'Niue', es: 'Niue', de: 'Niue' , it: 'Niue' },
{ code: 'NF', en: 'Norfolk Island', fr: 'Île Norfolk', es: 'Islas Norkfolk', de: 'Norfolkinsel' , it: 'Norfolkinsel' },
{ code: 'NO', en: 'Norway', fr: 'Norvège', es: 'Noruega', de: 'Norwegen' , it: 'Norvegia' },
{ code: 'NC', en: 'New Caledonia', fr: 'Nouvelle-Calédonie', es: 'Nueva Caledonia', de: 'Neukaledonien' , it: 'Nuova Caledonia' },
{ code: 'NZ', en: 'New Zealand', fr: 'Nouvelle-Zélande', es: 'Nueva Zelanda', de: 'Neuseeland' , it: 'Nuova Zelanda' },
{ code: 'OM', en: 'Oman', fr: 'Oman', es: 'Omán', de: 'Oman' , it: 'Oman' },
{ code: 'NL', en: 'Netherlands', fr: 'Pays-Bas', es: 'Países Bajos', de: 'Niederlande' , it: 'Paesi Bassi' },
{ code: 'PK', en: 'Pakistan', fr: 'Pakistan', es: 'Pakistán', de: 'Pakistan' , it: 'Pakistan' },
{ code: 'PW', en: 'Palau', fr: 'Palau', es: 'Islas Palaos', de: 'Palau' , it: 'Palau' },
{ code: 'PS', en: 'Palestine, State of', fr: 'Palestine', es: 'Palestina', de: 'Staat Palästina' , it: 'Palestina' },
{ code: 'PA', en: 'Panama', fr: 'Panama', es: 'Panamá', de: 'Panama' , it: 'Panama' },
{ code: 'PG', en: 'Papua New Guinea', fr: 'Papouasie-Nouvelle-Guinée', es: 'Papúa Nueva Guinea', de: 'Papua-Neuguinea' , it: 'Papua Nuova Guinea' },
{ code: 'PY', en: 'Paraguay', fr: 'Paraguay', es: 'Paraguay', de: 'Paraguay' , it: 'Paraguay' },
{ code: 'PE', en: 'Peru', fr: 'Pérou', es: 'Perú', de: 'Peru' , it: 'Perù' },
{ code: 'PF', en: 'French Polynesia', fr: 'Polynésie française', es: 'Polinesia Francesa', de: 'Französisch-Polynesien' , it: 'Polinesia francese' },
{ code: 'PL', en: 'Poland', fr: 'Pologne', es: 'Polonia', de: 'Polen' , it: 'Polonia' },
{ code: 'PT', en: 'Portugal', fr: 'Portugal', es: 'Portugal', de: 'Portugal' , it: 'Portogallo' },
{ code: 'PR', en: 'Puerto Rico', fr: 'Puerto Rico', es: 'Puerto Rico', de: 'Puerto Rico' , it: 'Puerto Rico' },
{ code: 'QA', en: 'Qatar', fr: 'Qatar', es: 'Qatar', de: 'Katar' , it: 'Qatar' },
{ code: 'GB', en: 'United Kingdom', fr: 'Royaume-Uni', es: 'Reino Unido', de: 'Vereinigtes Königreich Großbritannien und Nordirland' , it: 'Regno Unito' },
{ code: 'CZ', en: 'Czech Republic', fr: 'République tchèque', es: 'República Checa', de: 'Tschechien' , it: 'Repubblica Ceca' },
{ code: 'CF', en: 'Central African Republic', fr: 'République centrafricaine', es: 'República Centro-Africana', de: 'Zentralafrikanische Republik' , it: 'Repubblica Centraficana' },
{ code: 'CG', en: 'Congo', fr: 'République du Congo', es: 'Congo', de: 'Kongo, Republik' , it: 'Repubblica del Congo' },
{ code: 'CD', en: 'Congo, the Democratic Republic of the', fr: 'République démocratique du Congo', es: 'República democrática del Congo', de: 'Kongo, Demokratische Republik' , it: 'Repubblica Democratica del Congo' },
{ code: 'DO', en: 'Dominican Republic', fr: 'République dominicaine', es: 'República Dominicana', de: 'Dominikanische Republik' , it: 'Repubblica Dominicana' },
{ code: 'RO', en: 'Romania', fr: 'Roumanie', es: 'Rumanía', de: 'Rumänien' , it: 'Romania' },
{ code: 'RW', en: 'Rwanda', fr: 'Rwanda', es: 'Ruanda', de: 'Ruanda' , it: 'Ruanda' },
{ code: 'RU', en: 'Russian Federation', fr: 'Russie', es: 'Rusia', de: 'Russische Föderation' , it: 'Russia' },
{ code: 'EH', en: 'Western Sahara', fr: 'Sahara occidental', es: 'Sahara Occidental', de: 'Westsahara' , it: 'Sahara Occidentale' },
{ code: 'KN', en: 'Saint Kitts and Nevis', fr: 'Saint-Kitts-et-Nevis', es: 'San Cristóbal y Nieves', de: 'St. Kitts und Nevis' , it: 'Saint Kitts e Nevis' },
{ code: 'LC', en: 'Saint Lucia', fr: 'Sainte-Lucie', es: 'Santa Lucía', de: 'St. Lucia' , it: 'Saint Lucia' },
{ code: 'VC', en: 'Saint Vincent and the Grenadines', fr: 'Saint-Vincent-et-les Grenadines', es: 'San Vicente y las Granadinas', de: 'St. Vincent und die Grenadinen' , it: ' Saint Vincent e Grenadine' },
{ code: 'BL', en: 'Saint Barthélemy', fr: 'Saint-Barthélemy', es: 'San Bartolomé', de: 'Saint-Barthélemy' , it: 'Saint-Barthélemy' },
{ code: 'MF', en: 'Saint Martin (French part)', fr: 'Saint-Martin (partie française)', es: 'San Martín (parte francesa)', de: 'Saint-Martin (franz. Teil)' , it: 'Saint-Martin (parte francese)' },
{ code: 'PM', en: 'Saint Pierre and Miquelon', fr: 'Saint-Pierre-et-Miquelon', es: 'San Pedro y Miquelón', de: 'Saint-Pierre und Miquelon' , it: '  Saint-Pierre e Miquelon' },
{ code: 'WS', en: 'Samoa', fr: 'Samoa', es: 'Samoa', de: 'Samoa' , it: 'Samoa' },
{ code: 'AS', en: 'American Samoa', fr: 'Samoa américaines', es: 'Samoa Americana', de: 'Amerikanisch-Samoa', it: 'Samoa Americane' },
{ code: 'SM', en: 'San Marino', fr: 'Saint-Marin', es: 'San Marino', de: 'San Marino' , it: 'San Marino' },
{ code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fr: 'Sainte-Hélène', es: 'Santa Elena', de: 'St. Helena' , it: 'Sant\'Elena, Ascensione e Tristan da Cunha' },
{ code: 'ST', en: 'Sao Tome and Principe', fr: 'Sao Tomé-et-Principe', es: 'Santo Tomé y Príncipe', de: 'São Tomé und Príncipe' , it: 'São Tomé e Príncipe' },
{ code: 'SN', en: 'Senegal', fr: 'Sénégal', es: 'Senegal', de: 'Senegal' , it: 'Senegal' },
{ code: 'RS', en: 'Serbia', fr: 'Serbie', es: 'Serbia y Montenegro', de: 'Serbien' , it: 'Serbia' },
{ code: 'SC', en: 'Seychelles', fr: 'Seychelles', es: 'Seychelles', de: 'Seychellen' , it: 'Seychelles' },
{ code: 'SL', en: 'Sierra Leone', fr: 'Sierra Leone', es: 'Sierra Leona', de: 'Sierra Leone' , it: 'Sierra Leone' },
{ code: 'SG', en: 'Singapore', fr: 'Singapour', es: 'Singapur', de: 'Singapur' , it: 'Singapore' },
{ code: 'SX', en: 'Sint Maarten (Dutch part)', fr: 'Saint-Martin (partie néerlandaise)', es: 'San Martín (parte neerlandesa)', de: 'Sint Maarten (niederl. Teil)' , it: ' Sint Maarten' },
{ code: 'SY', en: 'Syrian Arab Republic', fr: 'Syrie', es: 'Siria', de: 'Syrien, Arabische Republik' , it: 'Siria' },
{ code: 'SK', en: 'Slovakia', fr: 'Slovaquie', es: 'Eslovaquia', de: 'Slowakei' , it: 'Slovacchia' },
{ code: 'SI', en: 'Slovenia', fr: 'Slovénie', es: 'Eslovenia', de: 'Slowenien' , it: 'Slovenia' },
{ code: 'SO', en: 'Somalia', fr: 'Somalie', es: 'Somalia', de: 'Somalia' , it: 'Somalia' },
{ code: 'ES', en: 'Spain', fr: 'Espagne', es: 'España', de: 'Spanien' , it: 'Spagna' },
{ code: 'LK', en: 'Sri Lanka', fr: 'Sri Lanka', es: 'Sri Lanka', de: 'Sri Lanka' , it: 'Sri Lanka' },
{ code: 'US', en: 'United States', fr: 'États-Unis', es: 'Estados Unidos de América', de: 'Vereinigte Staaten von Amerika' , it: 'Stati Uniti d\'America' },
{ code: 'ZA', en: 'South Africa', fr: 'Afrique du Sud', es: 'Sudáfrica', de: 'Südafrika' , it: 'Sudafrica' },
{ code: 'SD', en: 'Sudan', fr: 'Soudan', es: 'Sudán', de: 'Sudan' , it: 'Sudan' },
{ code: 'SS', en: 'South Sudan', fr: 'Sud-Soudan', es: 'Sudán del Sur', de: 'Südsudan' , it: 'Sudan del Sud' },
{ code: 'SR', en: 'Suriname', fr: 'Suriname', es: 'Surinam', de: 'Suriname' , it: 'Suriname' },
{ code: 'SJ', en: 'Svalbard and Jan Mayen', fr: 'Svalbard et Jan Mayen', es: 'Islas Svalbard y Jan Mayen', de: 'Svalbard und Jan Mayen' , it: 'Svalbard e Jan Mayen' },
{ code: 'SE', en: 'Sweden', fr: 'Suède', es: 'Suecia', de: 'Schweden' , it: 'Svezia' },
{ code: 'CH', en: 'Switzerland', fr: 'Suisse', es: 'Suiza', de: 'Schweiz' , it: 'Svizzera' },
{ code: 'TJ', en: 'Tajikistan', fr: 'Tadjikistan', es: 'Tayikistán', de: 'Tadschikistan' , it: '  Tagikistan' },
{ code: 'TW', en: 'Taiwan', fr: 'Taiwan', es: 'Taiwán', de: 'Taiwan' , it: 'Taiwan' },
{ code: 'TZ', en: 'Tanzania', fr: 'Tanzanie', es: 'Tanzania', de: 'Tansania' , it: 'Tanzania' },
{ code: 'TF', en: 'French Southern Territories', fr: 'Terres australes et antarctiques françaises', es: 'Territorios Australes Franceses', de: 'Französische Süd- und Antarktisgebiete' , it: 'Terre australi e antartiche francesi' },
{ code: 'IO', en: 'British Indian Ocean Territory', fr: 'Territoire britannique de l’Océan Indien', es: 'Territorio Británico del Océano Índico', de: 'Britisches Territorium im Indischen Ozean', it: 'Territorio Britannico dell\'Oceano Indiano' },
{ code: 'TH', en: 'Thailand', fr: 'Thaïlande', es: 'Tailandia', de: 'Thailand' , it: 'Thailandia' },
{ code: 'TL', en: 'Timor-Leste', fr: 'Timor-Leste', es: 'Timor-Leste', de: 'Osttimor ' , it: 'Timor Est' },
{ code: 'TG', en: 'Togo', fr: 'Togo', es: 'Togo', de: 'Togo' , it: 'Togo' },
{ code: 'TK', en: 'Tokelau', fr: 'Tokelau', es: 'Tokelau', de: 'Tokelau' , it: 'Tokelau' },
{ code: 'TO', en: 'Tonga', fr: 'Tonga', es: 'Tonga', de: 'Tonga' , it: 'Tonga' },
{ code: 'TT', en: 'Trinidad and Tobago', fr: 'Trinité-et-Tobago', es: 'Trinidad y Tobago', de: 'Trinidad und Tobago' , it: 'Trinidad e Tobago' },
{ code: 'TN', en: 'Tunisia', fr: 'Tunisie', es: 'Túnez', de: 'Tunesien' , it: 'Tunisia' },
{ code: 'TR', en: 'Turkey', fr: 'Turquie', es: 'Turquía', de: 'Türkei' , it: 'Turchia' },
{ code: 'TM', en: 'Turkmenistan', fr: 'Turkménistan', es: 'Turkmenistán', de: 'Turkmenistan' , it: 'Turkmenistan' },
{ code: 'TV', en: 'Tuvalu', fr: 'Tuvalu', es: 'Tuvalu', de: 'Tuvalu' , it: 'Tuvalu' },
{ code: 'UA', en: 'Ukraine', fr: 'Ukraine', es: 'Ucrania', de: 'Ukraine' , it: 'Ucraina' },
{ code: 'UG', en: 'Uganda', fr: 'Ouganda', es: 'Uganda', de: 'Uganda' , it: 'Uganda' },
{ code: 'HU', en: 'Hungary', fr: 'Hongrie', es: 'Hungría', de: 'Ungarn' , it: 'Ungheria' },
{ code: 'UY', en: 'Uruguay', fr: 'Uruguay', es: 'Uruguay', de: 'Uruguay' , it: 'Uruguay' },
{ code: 'UZ', en: 'Uzbekistan', fr: 'Ouzbékistan', es: 'Uzbekistán', de: 'Usbekistan' , it: 'Uzbekistan' },
{ code: 'VU', en: 'Vanuatu', fr: 'Vanuatu', es: 'Vanuatu', de: 'Vanuatu' , it: 'Vanuatu' },
{ code: 'VE', en: 'Venezuela', fr: 'Venezuela', es: 'Venezuela', de: 'Venezuela' , it: 'Venezuela' },
{ code: 'VN', en: 'Viet Nam', fr: 'Viêt Nam', es: 'Vietnam', de: 'Vietnam' , it: 'Vietnam' },
{ code: 'WF', en: 'Wallis and Futuna', fr: 'Wallis-et-Futuna', es: 'Wallis y Futuna', de: 'Wallis und Futuna' , it: 'Wallis e Futuna' },
{ code: 'YE', en: 'Yemen', fr: 'Yémen', es: 'Yemen', de: 'Jemen' , it: 'Yemen' },
{ code: 'ZM', en: 'Zambia', fr: 'Zambie', es: 'Zambia', de: 'Sambia' , it: 'Zambia' },
{ code: 'ZW', en: 'Zimbabwe', fr: 'Zimbabwe', es: 'Zimbabue', de: 'Simbabwe' , it: 'Zimbabwe' },
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    const countryName = c[lang] ? c[lang] : c['it'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
