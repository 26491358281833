import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  // {
  //   id: 'default-helsinki',
  //   predictionPlace: {
  //     address: 'Helsinki, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.29783, 25.25448), new LatLng(59.92248, 24.78287)),
  //   },
  // },
  // {
  //   id: 'default-turku',
  //   predictionPlace: {
  //     address: 'Turku, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.53045, 22.38197), new LatLng(60.33361, 22.06644)),
  //   },
  // },
  // {
  //   id: 'default-tampere',
  //   predictionPlace: {
  //     address: 'Tampere, Finland',
  //     bounds: new LatLngBounds(new LatLng(61.83657, 24.11838), new LatLng(61.42728, 23.5422)),
  //   },
  // },
  // {
  //   id: 'default-oulu',
  //   predictionPlace: {
  //     address: 'Oulu, Finland',
  //     bounds: new LatLngBounds(new LatLng(65.56434, 26.77069), new LatLng(64.8443, 24.11494)),
  //   },
  // },
  // {
  //   id: 'default-ruka',
  //   predictionPlace: {
  //     address: 'Ruka, Finland',
  //     bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
  //   },
  // },
 // {
 //   id: 'default-alghero',
 //   predictionPlace: {
 //     address: 'Alghero, Italia',
 //     bounds: new LatLngBounds(new LatLng(40.57616523, 8.34193979), new LatLng(40.54525044, 8.30183221)),
 //   },
 // },
  {
    id: 'default-cagliari',
    predictionPlace: {
      address: 'Cagliari, Italia',
      bounds: new LatLngBounds(new LatLng(39.282832, 9.1964744), new LatLng(39.1317516, 9.010771)),
    },
  },
//  {
//    id: 'default-nuoro',
//    predictionPlace: {
//      address: 'Nuoro, Italia',
//      bounds: new LatLngBounds(new LatLng(40.411074, 9.426042), new LatLng(40.25395, 9.182983)),
//    },
//  },
//  {
//    id: 'default-oristano',
//    predictionPlace: {
//      address: 'Oristano, Italia',
//      bounds: new LatLngBounds(new LatLng(39.970984, 8.729697), new LatLng(39.859222, 8.4791987)),
//    },
//  },
  {
    id: 'default-sassari',
    predictionPlace: {
      address: 'Sassari, Italia',
      bounds: new LatLngBounds(new LatLng(40.77828409, 8.63283151), new LatLng(40.66877061, 8.49040615)),
    },
  },
//  {
//    id: 'default-obia',
//    predictionPlace: {
//      address: 'Olbia, Italia',
//      bounds: new LatLngBounds(new LatLng(41.058605, 9.8058417), new LatLng(40.731501, 9.347997)),
//    },
//  },
];
export default defaultLocations;
